@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  direction: rtl;
}

.add {
  bottom: 65px !important;
  transition: all 0.3s;
}


